import { useAuthStore } from '~/store/auth.store'

export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuthStore()

  if (!authStore.isAuthenticated) {
    const config = useRuntimeConfig()

    return navigateTo({
      path: `${config.public.AUTH_FRONTEND_URL}/passport/logic`,
      force: true
    }, { redirectCode: 302, external: true, replace: true })
  }
})
